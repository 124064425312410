import Vue from "vue";
import VueRouter from "vue-router";
import SunExpert from "../views/Home.vue";
import AuthService from "../services/AuthService";
import { children as general } from "@/router//general.js";
import { children as security } from "@/router//security.js";
import { children as querymanager } from "@/router//QueryManager.js";
import { children as collecttion } from "@/router//Collection.js";
import { children as creep } from "@/router//Creep.js";
import { children as reportclicks } from "@/router//ReportClicks.js";
import { children as accounting } from "@/router//Accounting.js";
import { children as payment } from "@/router//Payment.js";
import { children as logistics } from "@/router//logistics.js";
import { children as inventory } from "@/router//Inventory.js";
import { children as visits } from "@/router//Visits.js";
import { children as customersupport } from "@/router//CustomerSupport.js";
import { children as management } from "@/router//management.js";
import { children as documents } from "@/router//documents.js";
import { children as legal } from "@/router//legal.js";
import { children as marketing } from "@/router//marketing.js";


Vue.use(VueRouter);

let children = [];

children.push(...general);
children.push(...security);
children.push(...querymanager);
children.push(...collecttion);
children.push(...creep);
children.push(...reportclicks);
children.push(...accounting);
children.push(...payment);
children.push(...logistics);
children.push(...inventory);
children.push(...visits);
children.push(...customersupport);
children.push(...management);
children.push(...documents);
children.push(...legal);
children.push(...marketing);


const routes = [{
        path: "*",
        redirect: "/micuenta",
    },

    {
        path: "/",
        name: "HOME",
        component: SunExpert,
        meta: {
            authenticated: true,
            breadcrumb: [{ text: "Inicio", disabled: false, href: "/#/" }],
        },
        children: children,
    },

    {
        path: "/seguridad/controlacceso",
        name: "SEC_ACCESSCONTROL",
        component: () =>
            import ("../views/Management/AccessControlDetail.vue"),
        meta: {
            authenticated: true,
            breadcrumb: [
                { text: "Inicio", href: "/#/" },
                { text: "seguridad", disabled: true },
                { text: "Control de Acceso", href: "/seguridad/controlacceso" },
            ],
        },
    },

    {
        path: "/controlcliente",
        name: "MNG_ACCESSSUTOMER",
        component: () =>
            import ("../views/Management/AccessCustomer/AccessControlCustomer.vue"),
        meta: {
            authenticated: true,
            breadcrumb: [
                { text: "Inicio", href: "/#/" },
                { text: "Acceso Clientes", disabled: true },
                { text: "Registro Visitas", href: "/controlcliente" },
            ],
        },
    },
    /* 
        {
            path: "/login",
            name: "LOGIN",
            breadcrumb: [{
                text: "login",
                disabled: false,
                href: "login",
            }, ],
            component: () =>
                import ("../views/Auth/Login.vue"),
        }, */

    {
        path: "/micuenta",
        name: "LOGIN",
        breadcrumb: [{
            text: "login2",
            disabled: false,
            href: "login2",
        }, ],
        component: () =>
            import ("../views/Auth/LoginCustomer.vue"),
        /* import ("../views/Auth/LoginCustomer.vue"), */

    },
    {
        path: "/referidos",
        name: "LOGIN",
        breadcrumb: [{
            text: "referidos",
            disabled: false,
            href: "referidos",
        }, ],
        component: () =>
            import ("../views/WebSiteIMP/WebReferrals.vue"),
    },
    {
        path: "/reportecomercial",
        name: "LOGIN",
        breadcrumb: [{
            text: "reportecomercial",
            disabled: false,
            href: "reportecomercial",
        }, ],
        component: () =>
            import ("../views/Commercial/CmmReportBI.vue"),
    },
    {
        path: "/logout",
        name: "LOGIN222",
        breadcrumb: [{
            text: "logout",
            disabled: false,
            href: "logout",
        }, ],
        component: () =>
            import ("../views/Auth/Logout.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {

    let activeSession = localStorage.getItem("UsrID") != null ? true : false;
    let authenticated = to.matched.some((record) => record.meta.authenticated);

    if (to.name.toUpperCase() != "HOME" && to.name.toUpperCase() != "LOGIN") {

        AuthService.userOption(localStorage.getItem("UsrID"), to.name).then((r) => {
            if (r.data.UsoID != null) {
                to.params.security = r.data;

                if (authenticated && !activeSession) next("micuenta");
                else if (!authenticated && activeSession) next("");
                else next();
            }
        });
    } else if (authenticated && !activeSession) next("micuenta");

    else if (!authenticated && activeSession) {
        next("");
    } else if (to.name.toUpperCase() == "HOME" && localStorage.getItem("UsrID") != null) {
        console.log("Holaaaaaaaaaaa")
        AuthService.userOption(localStorage.getItem("UsrID"), to.name).then((r) => {
            to.params.security = r.data;
            next();
        });
    } else next();
});

export default router;